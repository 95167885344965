import styled from "@emotion/styled"
import { darken } from "@mui/system"
import { Alert, Typography } from "@mui/material"
import Container from "@mui/material/Container"
import CustomButton from "../custom-button/custom-button.component"

export const Wrapper = styled.div`
  min-width: 350px;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 1rem 0 0 0;
  }
`

export const CustomContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: sticky;
    top: 6rem;
  }
`

export const Button = styled(CustomButton)`
  padding: ${({ theme }) => theme.typography.pxToRem(9)}
    ${({ theme }) => theme.typography.pxToRem(18)} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    div {
      color: white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    svg {
      width: 24px;
      height: 24px;
    }
    padding: ${({ theme }) => theme.typography.pxToRem(14)}
      ${({ theme }) => theme.typography.pxToRem(25)} !important;
  }
`

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  // span {
  //   font-weight: 600;
  //   color: ${({ theme }) => theme.palette.secondary.main};
  // }

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #00330e;
`

export const CustomAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.25rem;

  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 630px;
    margin-bottom: 0;
    align-items: flex-start;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-direction: column;
    gap: 24px;
  }
`

export const IconRow = styled.div`
  display: flex;
  gap: 0.5em;
`
export const IconWrapper = styled.a`
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00330e;
  cursor: pointer;

  &:hover {
    background-color: #e5e5ea;
  }

  svg {
    width: 19px;
    height: 19px;
    color: #00330e;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 48px;
    height: 48px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const Label = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #191d23;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(19)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(12)};
  }
`
